import * as React from "react"
import Container from '../components/container.js'
import ReactAudioPlayer from "react-audio-player";
import styled from 'styled-components';

import beethoven from '../music/beethoven.mp3';
import rachmaninoff from '../music/rachmaninoff.m4a';

import "@fontsource/montserrat";
import "@fontsource/eb-garamond";


// styles

const Body = styled.p`
  font-size: 18px;
  font-family: EB Garamond;
  font-weight: 300;
  width: 550px;
  text-align: justify;
  
  @media (max-width: 850px) {
    width: 425px;
    font-size: 17px;
  }

  @media (max-width: 500px) {
    width: 300px;
    font-size: 16px;
    text-align: left;
  }
`

const Divider = styled.div`
  border-bottom: 0.5px solid black;
  width: 550px;

  @media (max-width: 1009px) {
    width: 425px;
  }

  @media (max-width: 500px) {
    width: 300px;
  }
`

const MusicPage = () => {
  return (
    <Container>
      <Body> In my free time, I enjoy playing the piano. Here are two recordings — the first of Beethoven, and the second of Rachmaninoff that I performed with the cellist <span><a href="https://www.cys.org/media/artist_profiles/catherine_kim_cello.html">Catherine Kim</a></span>. </Body>
      
      <div style={{padding: 7}}/>
      <Divider/>
      <div style={{padding: 7}}/>

      {/* <div style={{display: 'flex', flexDirection: 'column', paddingTop: 20}}> */}

        <Body>Beethoven Sonata No. 30 in E Major, Op. 109, Movement 1</Body>
        <ReactAudioPlayer 
          src = {beethoven}
          controls
        />

        <div style={{padding: 15}}/>

        <Body>Rachmaninoff Sonata in G minor for Cello and Piano, Op. 19, Movement 3</Body>
        <ReactAudioPlayer 
          src = {rachmaninoff}
          controls
        />
      {/* </div> */}
    </Container>
  )
}

export default MusicPage